import { useEffect } from "react";
import { Manager } from "socket.io-client";
import {} from "socket.io-client";


export function HtmlKioskBridge({onUdp=null, onSerial=null}){
  useEffect(()=>{
    start({_address:"127.0.0.1", _port:"3001", onUdp, onSerial});
  },[]);
}



export const send = (type, msg) =>{
  if(!socket){
    console.log("socket not ready");
    return;
  }


  //console.log(type, msg)

  socket.emit(type, msg);
}
let manager, socket, address, port, oscObj;
export const start = ({_address="127.0.0.1", _port="3001", onUdp=null, onSerial=null}) =>{
  address = _address;
  port = _port;

  if(!manager){
    manager = new Manager(address+":"+port, {
      reconnectionDelayMax: 10000
    });
  }

  socket = manager.socket("/");

  console.log("start", address, port);
  socket.on("connect", () => {
    console.log("connected", socket.id);
  });

  socket.on("disconnect", () => {
    console.log(socket.id); // undefined
  });
  socket.on("msg", (...args) => {
   console.log(args);
  });
  socket.on("udp", (...args) => {

    if(args && args.length>0 && args[0].msg){
      onUdp && onUdp(args[0].msg);
    }else{
      onUdp && onUdp("");
    }
      ;
   });

   socket.on("serial", (...args) => {


    if(args && args.length>0 && args[0].msg){

      onSerial && onSerial(args[0].msg);

    }else{
      onSerial && onSerial([]);
    }
      ;
   });
}



