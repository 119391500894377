import {Manager} from "socket.io-client";


let manager, socket, clientId, role, app = null;

export function connectToAudioServer(station_uuid = null, cb){


  init(
    (role, app, station_uuid = null)=>{
      //station_uuid is set only if the client has writeaccess(sendAllowed/role=sender)
      window.socketIo = {role, app, station_uuid};
      console.log("socketIo", window.socketIo);

      //dispatch(setSocketIo({role, app, station_uuid}));
    },
    {
      key:process.env.REACT_APP_SOCKETIO_SENDER_SECRET, station_uuid:station_uuid || (crypto.randomUUID?crypto.randomUUID():createRandomString(10))
    }
  )
}

function createRandomString(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  result += new Date().getTime();
  return result;
}

function init(cb, credentials, onMessageHandler = null){
  console.log("init socketio", credentials);
  clientId = crypto.randomUUID?crypto.randomUUID():createRandomString(10);
  if(!manager)
    manager = new Manager(process.env.REACT_APP_SOCKETIO_SERVER, {
      reconnectionDelayMax: 10000
    });
 // console.log("userAgent", window.navigator.userAgent);
  //console.log("isSender", window.navigator.userAgent.indexOf("Safari/604.1")>-1);
  if(socket?.connected){
    socket.disconnect();
    socket.close();
  }
  socket = manager.socket("/", {
    auth: {
      token: process.env.REACT_APP_SOCKETIO_SECRET,
      app_key: credentials?.key || ""
    }
  });
  socket.on("connect_error", (err) => {
    console.log("connect_error", err); // not authorized
    /*
    console.log(err.message);
    console.log(err.description);
    console.log(err.context);
    */
  });
  socket.on("msg", (...args) => {
    if(args.length>0){
      let msg = args[0];
      if(!msg)
        return;
      //console.log("msg", msg);
      onMessageHandler && onMessageHandler(msg);
    }
  });
  socket.on("welcome", (msg) => {
    console.log("welcome connected", msg);
    role = msg.role;
    app = msg.app;
    if(role === "sender"){
      let station_APP_SOCKETIO_uuid = crypto.randomUUID?crypto.randomUUID():createRandomString(10);
      socket.emit("join", {room:station_APP_SOCKETIO_uuid});
      cb && cb(role, app, station_APP_SOCKETIO_uuid);
    }
    else
      cb && cb(role, app);
  });
  socket.on("connect", () => {
    console.log("connected");
    socket.on("disconnect", (msg) => {
      console.log("disconnected",msg);
    });
  });
  socket.on("joined", (payload) => {
    console.log("joined", payload.room);

  });
}

export function sendMsg(msg){
  /*
    msg.action = "play"|"paused"|"progress"|"ended"
  */

  //console.log("sendMsg", msg);
  if(role === "sender"){
    socket.emit("msg", { payload: msg});
  }
  else
    console.error("sending not allowed! check your role!");
}