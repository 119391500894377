import { LanguageString } from "../../utils/LanguageString";

let db = null;

export function ParseDB(raw, rawLang){
  let db = new DB(raw);


  console.log("DB", raw, rawLang);
  db.lang = {};
  for(let i in rawLang){
    db.lang[i] = new LanguageString(rawLang[i]);
  }

  for(let i in raw.lang){
    db.lang[i] = new LanguageString(raw.lang[i]);
  }



  return db;
}
class DB{
  constructor(raw, rawLang){
    this.settings = raw.settings;
    this.video = raw.video;
    if(window.device === "pc" && this.video?.indexOf("1573.mp4")> -1)
      this.video = "files/1271.mp4";
    console.log("DB", raw);
    //this.video = "files/1271.mp4";
    this.menu_icon = raw.menu_icon;
    if(raw.idle){
      this.idle = {
        bg: raw.idle.bg,
        btn: new LanguageString(raw.idle.btn)
      }
    }else{
      this.loop = true;
    }
    if(raw.subtitles)
      this.subtitles = new LanguageString(raw.subtitles);
  }
}