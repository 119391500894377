import { LanguageString } from "./LanguageString";

export const availableApps = {
    0.1:{
        id: "0.1",
        hash: "0.1",
        path: "0.1",
        cms_id:29,
        pos: { x: 0.92, y: 0.6 },
        size: { width: 1920, height: 1080 },
        player:"Brightsign",
        display:"Projector",
        name: "Infotheke",
        state: "dev",
        ip:"",
        mac:""
    },
    2.1:{
        id: "2.1",
        hash: "2.1",
        path: "2.1",
        cms_id:13,
        pos: { x: 0.62, y: 0.74 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "Muskatnuss / Nelken",
        state: "dev",
        ip:"",
        mac:""
    },

    2.2:{
        id: "2.2",
        hash: "2.2",
        path: "2.2",
        cms_id:19,
        pos: { x: 0.585, y: 0.67 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "Safran",
        state: "dev",
        ip:"",
        mac:""
    },
    2.3:{
        id: "2.3",
        hash: "2.3",
        path: "2.3",
        cms_id:20,
        pos: { x: 0.555, y: 0.72 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "Weihrauch",
        state: "dev",
        ip:"",
        mac:""
    },
    2.4:{
        id: "2.4",
        hash: "2.4",
        path: "2.4",
        cms_id:21,
        pos: { x: 0.535, y: 0.78 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "ohne Duft (Münzen)",
        state: "dev",
        ip:"",
        mac:""
    },
    2.5:{
        id: "2.5",
        hash: "2.5",
        path: "2.5",
        cms_id:22,
        pos: { x: 0.52, y: 0.66 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "Bergamotte",
        state: "dev",
        ip:"",
        mac:""
    },
    2.6:{
        id: "2.6",
        hash: "2.6",
        path: "2.6",
        cms_id:23,
        pos: { x: 0.49, y: 0.76 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "Acerola",
        state: "dev",
        ip:"",
        mac:""
    },
    2.7:{
        id: "2.7",
        hash: "2.7",
        path: "2.7",
        cms_id:24,
        pos: { x: 0.462, y: 0.69 },
        size: { width: 1280, height: 800 },
        player:"Brightsign",
        display:"Projector",
        name: "Kakao",
        state: "dev",
        ip:"",
        mac:""
    },
    "3.1.a": {
        id: "3.1.a",
        hash: "3.1.a",
        cms_id: 1,
        path: "3-mirror",
        pos: { x: 0.392, y: 0.687 },
        size: { width: 2160, height: 3840 },
        player:"StepPC",
        display:"49\"",
        name: "Spiegel",
        state: "dev",
        ip:"",
        mac:""
    },


    "3.1.b": {
        id: "3.1.b",
        hash: "3.1.b",
        cms_id: 1,
        path: "3-mirror",
        pos: { x: 0.345, y: 0.723 },
        size: { width: 2160, height: 3840 },
        player:"StepPC",
        display:"49\"",
        name: "Spiegel",
        state: "dev",
        ip:"",
        mac:""
    },


    "3.1.c": {
        id: "3.1.c",
        hash: "3.1.c",
        cms_id: 1,
        path: "3-mirror",
        pos: { x: 0.3, y: 0.685 },
        size: { width: 2160, height: 3840 },
        player:"StepPC",
        display:"49\"",
        name: "Spiegel",
        state: "dev",
        ip:"",
        mac:""

    },
    4.1:{
        id: "4.1",
        hash: "4.1",
        path: "4.1-video",
        cms_id:28,
        pos: { x: 0.1, y: 0.6 },
        size: { width: 1920, height: 1080 },
        player:"Brightsign",
        display:"Projector",
        name: "Vanillefilm",
        state: "dev",
        ip:"",
        mac:""
    },

    4.3: {
        id: "4.3",
        hash: "4.3",
        cms_id: 2,
        path: "4.3-companyHistory",
        pos: { x: 0.19, y: 0.55 },
        size: { width: 1920, height: 1080 },
        name: "Firmengeschichte",
        player:"StepPC",
        display:"15,3\"",
        state: "dev",
        ip:"",
        mac:""
    },
    4.6:{
        id: "4.6",
        hash: "4.6",
        path: "4.6-video",
        cms_id:17,
        pos: { x: 0.22, y: 0.685 },
        size: { width: 1920, height: 1080 },
        player:"Brightsign",
        display:"Projector",
        name: "Erfindung",
        state: "dev",
        ip:"",
        mac:""
    },

    "5.1a-left": {
        id: "5.1a-left",
        hash: "5.1a-left",
        cms_id: 3,
        path: "5.1-duftstoffBildschrime",
        pos: { x: 0.30, y: 0.48 },
        size: { width: 1024, height: 768 },
        name: "Duftstoff Bildschirm A",
        state: "dev",
        player:"Brightsign",
        display:"15\"",
        ip:"",
        mac:""
    },
    "5.1a-right": {
        id: "5.1a-right",
        hash: "5.1a-right",
        cms_id: 3,
        path: "5.1-duftstoffBildschrime",
        pos: { x: 0.37, y: 0.49 },
        size: { width: 1024, height: 768 },
        name: "Duftstoff Bildschirm A",
        state: "dev",
        player:"Brightsign",
        display:"15\"",
        ip:"",
        mac:""
    },
    "5.1b-left": {
        id: "5.1b-left",
        hash: "5.1b-left",
        cms_id: 3,
        path: "5.1-duftstoffBildschrime",
        pos: { x: 0.38, y: 0.425 },
        size: { width: 1024, height: 768 },
        name: "Duftstoff Bildschirm B",
        state: "dev",
        player:"Brightsign",
        display:"15\"",
        ip:"192.168.1.189",
        mac:""
    },
    "5.1b-right": {
        id: "5.1b-right",
        hash: "5.1b-right",
        cms_id: 3,
        path: "5.1-duftstoffBildschrime",
        pos: { x: 0.3, y: 0.41 },
        size: { width: 1024, height: 768 },
        name: "Duftstoff Bildschirm B",
        state: "dev",
        player:"Brightsign",
        display:"15\"",
        ip:"192.168.1.189",
        mac:""
    },
    "5.2a": {
        id: "5.2a",
        hash: "5.2a",
        cms_id: 4,
        path: "5.2-portrait",
        pos: { x: 0.545, y: 0.43 },

        size: { width: 1080, height: 1920 },
        name: "Mulekulare Synthese",
        state: "dev",
        player:"Brightsign",
        display:"43\"",
        comment:"",
        ip:"",
        mac:""
    },
    "5.2b": {
        id: "5.2b",
        hash: "5.2b",
        cms_id: 4,
        path: "5.2-portrait",
        pos: { x: 0.475, y: 0.345 },
        size: { width: 1080, height: 1920 },
        name: "Reaktionsaromen",
        state: "dev",
        player:"Brightsign",
        display:"43\"",
        comment:"",
        ip:"",
        mac:""
    },

    "5.2c": {
        id: "5.2c",
        hash: "5.2c",
        cms_id: 4,
        path: "5.2-portrait",
        pos: { x: 0.46, y: 0.44 },
        size: { width: 1080, height: 1920 },
        name: "Destillation",
        state: "dev",
        player:"Brightsign",
        display:"43\"",
        comment:"",
        ip:"",
        mac:""
    },
    "5.2d": {
        id: "5.2d",
        hash: "5.2d",
        cms_id: 4,
        path: "5.2-portrait",

        pos: { x: 0.55, y: 0.32 },
        size: { width: 1080, height: 1920 },
        name: "Biosynthese",
        state: "dev",
        player:"Brightsign",
        display:"43\"",
        comment:"",
        ip:"",
        mac:""
    },
    "5.2e": {
        id: "5.2e",
        hash: "5.2e",
        cms_id: 4,
        path: "5.2-portrait",
        pos: { x: 0.4, y: 0.37 },

        size: { width: 1080, height: 1920 },
        name: "Extraktion",
        state: "dev",
        player:"Brightsign",
        display:"43\"",
        comment:"",
        ip:"",
        mac:""
    },
    "6.1.a": {
        id: "6.1.a",
        hash: "6.1.a",
        cms_id: 5,
        path: "6.1.a-badezimmer",
        pos: { x: 0.655, y: 0.33 },
        size: { width: 1920, height: 1080 },
        name: "Badezimmer",
        state: "dev",
        player:"Brightsign",
        display:"32\"",
        ip:"",
        mac:""
    },
    "6.1.b": {
        id: "6.1.b",
        hash: "6.1.b",
        path: "6.1.b-supermarkt",
        cms_id: 14,
        pos: { x: 0.785, y: 0.2  },
        name: "Supermarkt",
        state: "dev",
        player:"ProDVX",
        display:"12\"",
        comment:"QR Scanner",
        ip:"",
        mac:""
    },
    "6.1.b.2": {
      id: "6.1.b.2",
      hash: "6.1.b.2",
      cms_id: 26,
      path: "6.1.b-drogeriemarkt",
      pos: { x: 0.835, y: 0.18 },
      name: "Drogeriemarkt",
      state: "dev",
      player:"ProDVX",
      display:"12\"",
      comment:"QR Scanner",
      ip:"",
      mac:""
  },
    "6.1.c-left": {
        id: "6.1.c-left",
        hash: "6.1.c-left",
        cms_id: 6,
        path: "6.1.c-snackAutomat",
        pos: { x: 0.65, y: 0.17 },
        size: { width: 1080, height: 1920 },
        name: "Snackautomat",
        state: "done",
        player:"Brightsign",
        os:"bs 9.0.145.1",
        display:"22\"",
        ip:"",
        mac:"",
        serial:""
    },
    "6.1.c-right": {
        id: "6.1.c-right",
        hash: "6.1.c-right",
        cms_id: 6,
        path: "6.1.c-snackAutomat",
        pos: { x: 0.7, y: 0.16 },
        size: { width: 1080, height: 1920 },
        name: "Snackautomat",
        state: "done",
        player:"Brightsign",
        os:"bs 9.0.145.1",
        display:"22\"",
        ip:"",
        mac:"",
        serial:""
    },
    "6.1.d": {
        id: "6.1.d",
        hash: "6.1.d",
        path: "6.1.d-herd",
        pos: { x: 0.87, y: 0.26 },
        size: { width: 1080, height: 1920 },
        rotation:90,
        cms_id:7,
        name: "Herd",

        state: "dev",
        player:"Brightsign",
        display:"32\"",
        ip:"",
        mac:""

    },
    "6.1.e": {
        id: "6.1.e",
        hash: "6.1.e",
        path: "6.1.e-roestaromen",
        cms_id: 8,
        pos: { x: 0.87, y: 0.34 },
        name: "Röstaromen",
        state: "dev",
        player:"Brightsign",
        display:"32\"",
        ip:"192.168.178.165",
        mac:""
    },
    "6.1.f": {
        id: "6.1.f",
        hash: "6.1.f",
        path: "6.1.f-table",
        cms_id: 9,
        pos: { x: 0.815, y: 0.285 },
        rotation:180,
        size: { width: 2160, height: 2160 },
        name: "Tisch",
        state: "dev",
        player:"StepPC",
        display:"Projector",
        ip:"",
        mac:""
    },
    "6.1.g": {
        id: "6.1.g",
        hash: "6.1.g",
        path: "6.1.g-getraenkekarte",
        cms_id : 10,
        pos: { x: 0.815, y: 0.4 },
        size: { width: 1920, height: 1080 },
        name: "Getränkekarte",
        state: "dev",
        player:"ProDVX",
        display:"12\"",
        ip:"",
        mac:""
    },

    "6.2.a": {
        id: "6.2.a",
        hash: "6.2.a",
        path: "6.2-wissenschaft",
        cms_id: 11,
        pos: { x: 0.91, y: 0.23 },
        size: { width: 1080, height: 1920 },
        name: "Düfte und Wissenschaft",
        player:"Brightsign",
        state: "dev",
        ip:"",
        mac:""
    },
    "7.a": {
        id: "7.a",
        hash: "7.a",
        path: "7-duftGenerator",
        cms_id: 15,
        pos: { x: 0.15, y: 0.18 },
        size: { width: 1920, height: 1080 },
        name: "Duftgenerator",
        state: "dev",
        player:"StepPC",
        display:"24\"",
        comment:"",
        ip:"",
        mac:""
    },
    "7.b": {
        id: "7.b",
        hash: "7.b",
        path: "7-duftGenerator",
        cms_id: 15,
        pos: { x: 0.19, y: 0.23 },
        size: { width: 1920, height: 1080 },
        name: "Duftgenerator",
        state: "dev",
        player:"StepPC",
        display:"24\"",
        comment:"",
        ip:"",
        mac:""
    },
    "7.c": {
        id: "7.c",
        hash: "7.c",
        path: "7-duftGenerator",
        cms_id: 15,
        pos: { x: 0.1, y: 0.24 },
        size: { width: 1920, height: 1080 },
        name: "Duftgenerator",
        state: "dev",
        player:"StepPC",
        display:"24\"",
        comment:"",
        ip:"",
        mac:""
    },
};

let determinatedApp = null;
export const getDataUrl = () => {


    let app = determinatedApp;

    //return process.env.PUBLIC_URL + "/_content/" + app.path + "/data.json";
    if (app.cms_id == null || window.offline)
        return process.env.PUBLIC_URL + "/_content/" + app.path + "/data.json";
    return process.env.REACT_APP_CMS_URL + "station/get/" + app.cms_id;
};

export const getMediaPath = (file) => {
    if (!file) return "";
    ///_content/6.1.c-snackAutomat/files/17.png
    let app = determinatedApp;
    if (!window.offline && app.cms_id == null)
        return process.env.PUBLIC_URL + "/_content/" + app.path + "/" + file;

    //console.log("getMediaPath", file);
    file = file.replace("files/", "");
    //console.log("getMediaPath", file, window.offline, window.device);
    if(window.offline)
    {
        if(window.device === "brightsign" || app.cms_id === 15)
            return "/_content/" + app.path + "/" + file;
        else
            return process.env.PUBLIC_URL + "/_content/" + app.path + "/" + file;
    }

    return process.env.REACT_APP_CMS_URL + "getfile/" + file;
};
export const loadActualApp = async (loaded) => {
    if (!determinatedApp) determinatedApp = await determinateActualAppName();
    let app = determinatedApp;

    let url = getDataUrl();
    //console.log("loadActualApp", url);
    fetch(url)
        .then((e) => e.text())
        .then((e) => {
            try {
                return JSON.parse(e);
            } catch (Ex) {
                console.error("Error parsing JSON", e);
            }

            return {};
        })
        .then((e) => {
            console.log("loadActualApp", e);
            loadGenericLang((lang) => {
                loaded && loaded({ app, data: e, lang });
            });
        });
};

const loadGenericLang = (loaded) => {
    fetch(process.env.PUBLIC_URL + "/_content/lang.json")
        .then((e) => e.text())
        .then((e) => {
            return JSON.parse(e);
        })
        .then((e) => {
            loaded && loaded(e);
        });
};
const determinateActualAppName = async () => {
    let app = null;
    if (Object.values(availableApps).length > 0)
        app = Object.values(availableApps)[0];
    console.log("determinateActualAppName", app);
    let hash = document.location.hash;

    for (let i in availableApps) {
        if (
            hash.indexOf(availableApps[i].hash) > -1 &&
            hash.split("/")[0] === "#" + availableApps[i].hash
        ) {
            app = availableApps[i];
            break;
        }
    }
    return app;
};

const htmlRegex = /<\/?[a-z][\s\S]*>/i;
export function IsHtml(txt) {
    return htmlRegex.test(txt);
}

export const createDigitHash = (str, length) => {
    let hash = Math.abs(sdbm(str)).toString();
    //console.log(str, hash);
    if (hash.length > length)
        hash = hash.substring(hash.length - length, hash.length);
    else hash = hash.padStart(length, "0");
    return hash;
};

export const sdbm = (str) => {
    let arr = str.split("");
    return arr.reduce(
        (hashCode, currentVal) =>
            (hashCode =
                currentVal.charCodeAt(0) +
                (hashCode << 6) +
                (hashCode << 16) -
                hashCode),
        0
    );
};

var dpr = window.devicePixelRatio;
var inch = 25.4; //1inch = 25.4 mm
export let ppi = parseInt(localStorage.getItem("ppi")) || 165; //Ipad3 density

export function mmToPx(mm, _ppi = ppi) {
    return ((mm / inch) * _ppi) / dpr;
}

export function isVideo(src) {
    return src?.indexOf(".mp4") > -1;
}
